import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class InfoConsultada {

  // quemado <future> temp:
  // LAS SIGUIENTES 2 PROPIEDADES SON QUEMADAS MIENTRAS LOS WS SE COMPLETAN
  public tieneCuentaCoopedac:boolean;
  public maxPorcentajeBeneficiariosPermitido:number = 100;
  public minPdfCertFileSize:number = 100; // ~ 100kb

  private static instancia  : InfoConsultada;
  public isReady            : boolean;
  private info              : Map<string, any>;

  // Singleton
  private constructor () {
    this.info     = new Map();
    this.isReady  = true;
  }



  public setInfo = (key:string, value:any) => {
    switch(typeof(value)) {
      case 'string':    this.info.set(key, btoa(value));
      break;
      case 'undefined': /*No hace nada*/
      break;
      default:          this.info.set(key, value);
      break;
    }
    this.actualizar();
  }



  public getInfo = (key:string) => {
    if(this.info.get(key)) {
      return typeof(this.info.get(key)) == 'string' ?
        atob(this.info.get(key)) : this.info.get(key);
    }
  }



  public deleteInfo = (key:string) : boolean => {
    if(this.info.has(key))
      this.info.delete(key);
    this.actualizar();
    return !(this.info.get(key));
  }




  public clear() {
    this.info.clear();
  }




  // Este método se usa para mostrar lo que hay en el singleton ahora mismo
  public toJSON () : string {
    if(this.info.size < 1) return '';
    let resultado = '{';
    this.info.forEach((val,key) => {
      resultado += '"'+key+'": '+(
        typeof(val) == 'string'?
        '"'+atob(val)+'"' :
        JSON.stringify(val)
      )+',\n';
    });
    return resultado.slice(0,-2)+'}';
  }



  public toString = ()=>{console.log(this.info);}



  // Este método se usa importar el estado actual del programa
  // y así poder desarrollar pantallas posteriores
  public importProgramState (json:string) : Promise<string> {

    return new Promise((resolve, reject)=>{
      let contador = 0
      try {
        let input = JSON.parse(json);
        for(let i in input) {
          switch(typeof(input[i])) {
            case 'string':    this.info.set(i, btoa(input[i]));
            break;
            case 'undefined': /*No hace nada*/
            break;
            default:          this.info.set(i, input[i]);
            break;
          }
          contador++;
        }
        this.actualizar();
        resolve('IMPORT OK, '+contador+' ELEMENTOS');
      }
      catch(error) {
        reject('Hubo un error al importar! '+error.message);
      }
    });
  }



  // SINGLETON GETINSTANCE
  public static getInstancia() : InfoConsultada {
    if(this.instancia == undefined || this.instancia == null)
      this.instancia = new InfoConsultada();
    return this.instancia;
  }



  // REALIZA ACCIONES CUANDO PASA UN CLAMBIO EN EL SINGLETON
  public actualizar = () => sessionStorage.setItem('info', this.toJSON());




  // LE DICE A LAS PÁGINAS QUE NO LEAN INFORMACIÓN HASTA QUE EL SINGLETON RETOME DEL SESSIONSSTORAGE
  public waitInfo() : Promise<void>{
    return new Promise((resolve, reject)=>{
      let waits = 0;
      let waiting = setInterval(()=>{
        if(this.isReady) {
          resolve();
          clearInterval(waiting);
        }
        else if(waits > 3){
          reject();
          clearInterval(waiting);
        }
        else waits++;
      }, 1000);
    });
  }




  // ESPACIO TEMPORAL PARA CARGAR INFORMACIÓN
  public importarEstado() : Promise<string> {
    let json
      = `{
        "rol":"cliente",
        "login":{
           "documento":"1013643973",
           "tipoDocumento":1
        },
        "tiposContrato":{
           "BRta":true,
           "SMsjConsulta":"Consulta exitosa.",
           "listTipoContr":{
              "TipoContratoDTO":[
                 {
                    "ICodigo":1,
                    "SDescripcion":"INDEFINIDO"
                 },
                 {
                    "ICodigo":3,
                    "SDescripcion":"INDEPENDIENTE"
                 },
                 {
                    "ICodigo":4,
                    "SDescripcion":"NINGUNO"
                 },
                 {
                    "ICodigo":6,
                    "SDescripcion":"NO APLICA"
                 },
                 {
                    "ICodigo":7,
                    "SDescripcion":"OTROS"
                 },
                 {
                    "ICodigo":9,
                    "SDescripcion":"PENSIONADO"
                 },
                 {
                    "ICodigo":5,
                    "SDescripcion":"PRESTACION DE SERVICIOS"
                 },
                 {
                    "ICodigo":2,
                    "SDescripcion":"TERMINO FIJO "
                 }
              ]
           }
        },
        "tiposCiudad":{
           "BRta":true,
           "SMsjConsulta":"Consulta exitosa.",
           "listCiudades":{
              "CiudadDTO":[
                 {
                    "I_Codigo":"5002",
                    "C_Nombre":"ABEJORRAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54003",
                    "C_Nombre":"ABREGO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5004",
                    "C_Nombre":"ABRIAQUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50006",
                    "C_Nombre":"ACACIAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27006",
                    "C_Nombre":"ACANDI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41006",
                    "C_Nombre":"ACEVEDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13006",
                    "C_Nombre":"ACHI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41013",
                    "C_Nombre":"AGRADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25001",
                    "C_Nombre":"AGUA DE DIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20011",
                    "C_Nombre":"AGUACHICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68013",
                    "C_Nombre":"AGUADA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17013",
                    "C_Nombre":"AGUADAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85010",
                    "C_Nombre":"AGUAZUL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20013",
                    "C_Nombre":"AGUSTIN COD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41016",
                    "C_Nombre":"AIPE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25019",
                    "C_Nombre":"ALBAN CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52019",
                    "C_Nombre":"ALBAN NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18029",
                    "C_Nombre":"ALBANIA CAQUETA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68020",
                    "C_Nombre":"ALBANIA SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76020",
                    "C_Nombre":"ALCALA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52022",
                    "C_Nombre":"ALDANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5021",
                    "C_Nombre":"ALEJANDRIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99776",
                    "C_Nombre":"ALEMANIA",
                    "I_Nivel":"1",
                    "I_Ind_Nal":"",
                    "I_Ind_Internal":"",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41020",
                    "C_Nombre":"ALGECIRAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19022",
                    "C_Nombre":"ALMAGUER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15022",
                    "C_Nombre":"ALMEIDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73024",
                    "C_Nombre":"ALPUJARRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41026",
                    "C_Nombre":"ALTAMIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27025",
                    "C_Nombre":"ALTO BAUDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73026",
                    "C_Nombre":"ALVARADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5030",
                    "C_Nombre":"AMAGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5031",
                    "C_Nombre":"AMALFI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99038",
                    "C_Nombre":"AMANAVEN            ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"73030",
                    "C_Nombre":"AMBALEMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25035",
                    "C_Nombre":"ANAPOIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52036",
                    "C_Nombre":"ANCUYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76036",
                    "C_Nombre":"ANDALUCIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5034",
                    "C_Nombre":"ANDES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5036",
                    "C_Nombre":"ANGELOPOLIS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5038",
                    "C_Nombre":"ANGOSTURA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25040",
                    "C_Nombre":"ANOLAIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5040",
                    "C_Nombre":"ANORI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17042",
                    "C_Nombre":"ANSERMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76041",
                    "C_Nombre":"ANSERMANUEV",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5000",
                    "C_Nombre":"ANTIOQUIA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5044",
                    "C_Nombre":"ANZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73043",
                    "C_Nombre":"ANZOATEGUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5045",
                    "C_Nombre":"APARTADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66045",
                    "C_Nombre":"APIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15047",
                    "C_Nombre":"AQUITANIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47053",
                    "C_Nombre":"ARACATACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91065",
                    "C_Nombre":"ARACUARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17050",
                    "C_Nombre":"ARANZAZU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68051",
                    "C_Nombre":"ARATOCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"81001",
                    "C_Nombre":"ARAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"81065",
                    "C_Nombre":"ARAUQUITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25053",
                    "C_Nombre":"ARBELAEZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52051",
                    "C_Nombre":"ARBOLEDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54051",
                    "C_Nombre":"ARBOLEDAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5051",
                    "C_Nombre":"ARBOLETES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15051",
                    "C_Nombre":"ARCABUCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5055",
                    "C_Nombre":"ARGELIA ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19050",
                    "C_Nombre":"ARGELIA CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76054",
                    "C_Nombre":"ARGELIA VALLE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99769",
                    "C_Nombre":"ARGENTINA",
                    "I_Nivel":"1",
                    "I_Ind_Nal":"",
                    "I_Ind_Internal":"",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"47058",
                    "C_Nombre":"ARIGUANI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13052",
                    "C_Nombre":"ARJONA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5059",
                    "C_Nombre":"ARMENIA ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63001",
                    "C_Nombre":"ARMENIA QUINDIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73055",
                    "C_Nombre":"ARMERO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73067",
                    "C_Nombre":"ATACO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8000",
                    "C_Nombre":"ATLANTICO",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23068",
                    "C_Nombre":"AYAPEL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27073",
                    "C_Nombre":"BAGADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27075",
                    "C_Nombre":"BAHIA SOLAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27077",
                    "C_Nombre":"BAJO BAUDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19075",
                    "C_Nombre":"BALBOA CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66075",
                    "C_Nombre":"BALBOA RISARALDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8078",
                    "C_Nombre":"BARANOA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41078",
                    "C_Nombre":"BARAYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52079",
                    "C_Nombre":"BARBACOAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5079",
                    "C_Nombre":"BARBOSA ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68077",
                    "C_Nombre":"BARBOSA SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68079",
                    "C_Nombre":"BARICHARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76113002",
                    "C_Nombre":"BARQUISIMETO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"",
                    "I_Ind_Internal":"",
                    "I_Ubicacion":"99768"
                 },
                 {
                    "I_Codigo":"68081",
                    "C_Nombre":"BARRANCABER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44078",
                    "C_Nombre":"BARRANCAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13074",
                    "C_Nombre":"BARRANCO DE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"94343",
                    "C_Nombre":"BARRANCO MINAS GUAINIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8001",
                    "C_Nombre":"BARRANQUILLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"0",
                    "I_Ind_Internal":"0",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20045",
                    "C_Nombre":"BECERRIL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17088",
                    "C_Nombre":"BELALCAZAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15087",
                    "C_Nombre":"BELEN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18094",
                    "C_Nombre":"BELEN DE LO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66088",
                    "C_Nombre":"BELEN DE UM",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5088",
                    "C_Nombre":"BELLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5086",
                    "C_Nombre":"BELMIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25086",
                    "C_Nombre":"BELTRAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15090",
                    "C_Nombre":"BERBEO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5091",
                    "C_Nombre":"BETANIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15092",
                    "C_Nombre":"BETEITIVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5093",
                    "C_Nombre":"BETULIA ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68092",
                    "C_Nombre":"BETULIA SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25095",
                    "C_Nombre":"BITUIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15097",
                    "C_Nombre":"BOAVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54099",
                    "C_Nombre":"BOCHALEMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"11001",
                    "C_Nombre":"BOGOTA D.C.",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25099",
                    "C_Nombre":"BOJACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27099",
                    "C_Nombre":"BOJAYA (BEL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13000",
                    "C_Nombre":"BOLIVAR",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19100",
                    "C_Nombre":"BOLIVAR CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68101",
                    "C_Nombre":"BOLIVAR SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76100",
                    "C_Nombre":"BOLIVAR VALLE DEL CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"11102",
                    "C_Nombre":"BOSA                ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"99780",
                    "C_Nombre":"BOSCONIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"51",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"20000"
                 },
                 {
                    "I_Codigo":"15104",
                    "C_Nombre":"BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15000",
                    "C_Nombre":"BOYACA              ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"5107",
                    "C_Nombre":"BRISEÑO ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15106",
                    "C_Nombre":"BRISEÑO BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68001",
                    "C_Nombre":"BUCARAMANGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99778",
                    "C_Nombre":"Bucaramanga",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"51",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"68000"
                 },
                 {
                    "I_Codigo":"54109",
                    "C_Nombre":"BUCARASICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76109",
                    "C_Nombre":"BUENAVENTURA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15109",
                    "C_Nombre":"BUENAVISTA BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23079",
                    "C_Nombre":"BUENAVISTA CORDOBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63111",
                    "C_Nombre":"BUENAVISTA QUINDIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70110",
                    "C_Nombre":"BUENAVISTA SUCRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19110",
                    "C_Nombre":"BUENOS AIRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52110",
                    "C_Nombre":"BUESACO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76111",
                    "C_Nombre":"BUGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76113",
                    "C_Nombre":"BUGALAGRAND",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5113",
                    "C_Nombre":"BURITICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15114",
                    "C_Nombre":"BUSBANZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25120",
                    "C_Nombre":"CABRERA CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68121",
                    "C_Nombre":"CABRERA SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50124",
                    "C_Nombre":"CABUYARO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5120",
                    "C_Nombre":"CACERES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25123",
                    "C_Nombre":"CACHIPAY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54128",
                    "C_Nombre":"CACHIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54125",
                    "C_Nombre":"CACOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5125",
                    "C_Nombre":"CAICEDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76122",
                    "C_Nombre":"CAICEDONIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70124",
                    "C_Nombre":"CAIMITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73124",
                    "C_Nombre":"CAJAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19130",
                    "C_Nombre":"CAJIBIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25126",
                    "C_Nombre":"CAJICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99764",
                    "C_Nombre":"CALABAZO",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"44001"
                 },
                 {
                    "I_Codigo":"99763",
                    "C_Nombre":"CALABAZO ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"13140",
                    "C_Nombre":"CALAMAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63130",
                    "C_Nombre":"CALARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17000",
                    "C_Nombre":"CALDAS",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5129",
                    "C_Nombre":"CALDAS ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15131",
                    "C_Nombre":"CALDAS BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19137",
                    "C_Nombre":"CALDONO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76001",
                    "C_Nombre":"CALI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68132",
                    "C_Nombre":"CALIFORNIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76126",
                    "C_Nombre":"CALIMA (DAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19142",
                    "C_Nombre":"CALOTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5134",
                    "C_Nombre":"CAMPAMENTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8137",
                    "C_Nombre":"CAMPO DE LA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41132",
                    "C_Nombre":"CAMPOALEGRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15135",
                    "C_Nombre":"CAMPOHERMOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23090",
                    "C_Nombre":"CANALETE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5138",
                    "C_Nombre":"CANASGORDAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8141",
                    "C_Nombre":"CANDELARIA ATLANTICO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76130",
                    "C_Nombre":"CANDELARIA VALLE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25148",
                    "C_Nombre":"CAPARRAPI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68147",
                    "C_Nombre":"CAPITANEJO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18000",
                    "C_Nombre":"CAQUETA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25151",
                    "C_Nombre":"CAQUEZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99767",
                    "C_Nombre":"CARACAS VENEZUELA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"5142",
                    "C_Nombre":"CARACOLI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5145",
                    "C_Nombre":"CARAMANTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68152",
                    "C_Nombre":"CARCASI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5147",
                    "C_Nombre":"CAREPA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73148",
                    "C_Nombre":"CARMEN DE A",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25154",
                    "C_Nombre":"CARMEN DE C",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5148",
                    "C_Nombre":"CARMEN DE V",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5150",
                    "C_Nombre":"CAROLINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13001",
                    "C_Nombre":"CARTAGENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18050",
                    "C_Nombre":"CARTAGENA D",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18150",
                    "C_Nombre":"CARTAGENA DE  IN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76147",
                    "C_Nombre":"CARTAGO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"97161",
                    "C_Nombre":"CARURU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73152",
                    "C_Nombre":"CASABIANCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99773",
                    "C_Nombre":"CASANARE",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"",
                    "I_Ind_Internal":"",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99772",
                    "C_Nombre":"Casanare",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"57",
                    "I_Ind_Internal":"57",
                    "I_Ubicacion":"41206"
                 },
                 {
                    "I_Codigo":"50150",
                    "C_Nombre":"CASTILLA LA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99160",
                    "C_Nombre":"CASUARITO           ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"19000",
                    "C_Nombre":"CAUCA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5154",
                    "C_Nombre":"CAUCASIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76113001",
                    "C_Nombre":"CEILAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"76113"
                 },
                 {
                    "I_Codigo":"68160",
                    "C_Nombre":"CEPITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23162",
                    "C_Nombre":"CERETE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15162",
                    "C_Nombre":"CERINZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68162",
                    "C_Nombre":"CERRITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47161",
                    "C_Nombre":"CERRO SAN A",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20000",
                    "C_Nombre":"CESAR",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52240",
                    "C_Nombre":"CHACHAGUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25168",
                    "C_Nombre":"CHAGUANI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70230",
                    "C_Nombre":"CHALAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85015",
                    "C_Nombre":"CHAMEZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73168",
                    "C_Nombre":"CHAPARRAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68167",
                    "C_Nombre":"CHARALA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68169",
                    "C_Nombre":"CHARTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25175",
                    "C_Nombre":"CHIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5172",
                    "C_Nombre":"CHIGORODO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23168",
                    "C_Nombre":"CHIMA CORDOBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68176",
                    "C_Nombre":"CHIMA SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20175",
                    "C_Nombre":"CHIMICHAGUA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54172",
                    "C_Nombre":"CHINACOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15172",
                    "C_Nombre":"CHINAVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17174",
                    "C_Nombre":"CHINCHINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23182",
                    "C_Nombre":"CHINU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25178",
                    "C_Nombre":"CHIPAQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68179",
                    "C_Nombre":"CHIPATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15176",
                    "C_Nombre":"CHIQUINQUIR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15232",
                    "C_Nombre":"CHIQUIZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99781",
                    "C_Nombre":"Chiriguana",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"51",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"20000"
                 },
                 {
                    "I_Codigo":"15180",
                    "C_Nombre":"CHISCAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15183",
                    "C_Nombre":"CHITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54174",
                    "C_Nombre":"CHITAGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15185",
                    "C_Nombre":"CHITARAQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15187",
                    "C_Nombre":"CHIVATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47170",
                    "C_Nombre":"CHIVOLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25181",
                    "C_Nombre":"CHOACHI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27000",
                    "C_Nombre":"CHOCO",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25183",
                    "C_Nombre":"CHOCONTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47189",
                    "C_Nombre":"CIENAGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23189",
                    "C_Nombre":"CIENAGA DE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15189",
                    "C_Nombre":"CIENEGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68190",
                    "C_Nombre":"CIMITARRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63190",
                    "C_Nombre":"CIRCASIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5190",
                    "C_Nombre":"CISNEROS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5101",
                    "C_Nombre":"CIUDAD BOLIVAR ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5197",
                    "C_Nombre":"COCORNA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73200",
                    "C_Nombre":"COELLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25200",
                    "C_Nombre":"COGUA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41206",
                    "C_Nombre":"COLOMBIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"57",
                    "C_Nombre":"COLOMBIA",
                    "I_Nivel":"1",
                    "I_Ind_Nal":"57",
                    "I_Ind_Internal":"57",
                    "I_Ubicacion":"0"
                 },
                 {
                    "I_Codigo":"86219",
                    "C_Nombre":"COLON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52203",
                    "C_Nombre":"COLON (GENO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70204",
                    "C_Nombre":"COLOSO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15204",
                    "C_Nombre":"COMBITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99000",
                    "C_Nombre":"COMISARIA D         ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"97000",
                    "C_Nombre":"COMISARIA D         ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"95000",
                    "C_Nombre":"COMISARIA D         ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"94000",
                    "C_Nombre":"COMISARIA D         ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"5206",
                    "C_Nombre":"CONCEPCION ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68207",
                    "C_Nombre":"CONCEPCION SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5209",
                    "C_Nombre":"CONCORDIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27205",
                    "C_Nombre":"CONDOTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68209",
                    "C_Nombre":"CONFINES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52207",
                    "C_Nombre":"CONSACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99784",
                    "C_Nombre":"Consulado de Nueva York",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"52210",
                    "C_Nombre":"CONTADERO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68211",
                    "C_Nombre":"CONTRATACIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54206",
                    "C_Nombre":"CONVENCION",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5212",
                    "C_Nombre":"COPACABANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15212",
                    "C_Nombre":"COPER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23000",
                    "C_Nombre":"CORDOBA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13212",
                    "C_Nombre":"CORDOBA BOLIVAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52215",
                    "C_Nombre":"CORDOBA NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63212",
                    "C_Nombre":"CORDOBA QUINDIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19212",
                    "C_Nombre":"CORINTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68217",
                    "C_Nombre":"COROMORO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70215",
                    "C_Nombre":"COROZAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15215",
                    "C_Nombre":"CORRALES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25214",
                    "C_Nombre":"COTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15218",
                    "C_Nombre":"COVARACHIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73217",
                    "C_Nombre":"COYAIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"81220",
                    "C_Nombre":"CRAVO NORTE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52224",
                    "C_Nombre":"CUASPUD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15223",
                    "C_Nombre":"CUBARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50223",
                    "C_Nombre":"CUBARRAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15224",
                    "C_Nombre":"CUCAITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25224",
                    "C_Nombre":"CUCUNUBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54001",
                    "C_Nombre":"CUCUTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54223",
                    "C_Nombre":"CUCUTILLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18185",
                    "C_Nombre":"CUEMANI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15226",
                    "C_Nombre":"CUITIVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50226",
                    "C_Nombre":"CUMARAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52227",
                    "C_Nombre":"CUMBAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52233",
                    "C_Nombre":"CUMBITARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73226",
                    "C_Nombre":"CUNDAY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25000",
                    "C_Nombre":"CUNDINAMARC",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68229",
                    "C_Nombre":"CURITI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20228",
                    "C_Nombre":"CURUMANI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5234",
                    "C_Nombre":"DABEIBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76233",
                    "C_Nombre":"DAGUA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91000",
                    "C_Nombre":"DEPARTAMENT         ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"85000",
                    "C_Nombre":"DEPARTAMENT         ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"0",
                    "C_Nombre":"des",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44090",
                    "C_Nombre":"DIBULLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"0",
                    "I_Ind_Internal":"0",
                    "I_Ubicacion":"44000"
                 },
                 {
                    "I_Codigo":"73236",
                    "C_Nombre":"DOLORES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5237",
                    "C_Nombre":"DON MATIAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66170",
                    "C_Nombre":"DOS QUEBRAD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15238",
                    "C_Nombre":"DUITAMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54239",
                    "C_Nombre":"DURANIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5240",
                    "C_Nombre":"EBEJICO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"239",
                    "C_Nombre":"ECUADOR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76243",
                    "C_Nombre":"EL AGUILA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5250",
                    "C_Nombre":"EL BAGRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47245",
                    "C_Nombre":"EL BANCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76246",
                    "C_Nombre":"EL CAIRO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50245",
                    "C_Nombre":"EL CALVARIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27245",
                    "C_Nombre":"EL CARMEN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13244",
                    "C_Nombre":"EL CARMEN D",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54245",
                    "C_Nombre":"EL CARMEN NORTE DE SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50251",
                    "C_Nombre":"EL CASTILLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76248",
                    "C_Nombre":"EL CERRITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52250",
                    "C_Nombre":"EL CHARCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15244",
                    "C_Nombre":"EL COCUY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25245",
                    "C_Nombre":"EL COLEGIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20238",
                    "C_Nombre":"EL COPEY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18247",
                    "C_Nombre":"EL DONCELLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76250",
                    "C_Nombre":"EL DOVIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91263",
                    "C_Nombre":"EL ENCANTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15248",
                    "C_Nombre":"EL ESPINO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68245",
                    "C_Nombre":"EL GUACAMAY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13248",
                    "C_Nombre":"EL GUAMO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20250",
                    "C_Nombre":"EL PASO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18256",
                    "C_Nombre":"EL PAUJIL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25258",
                    "C_Nombre":"EL PENON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47258",
                    "C_Nombre":"EL PINON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"97210",
                    "C_Nombre":"EL RETORNO          ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"51749",
                    "C_Nombre":"EL ROSAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"1",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"25000"
                 },
                 {
                    "I_Codigo":"99775",
                    "C_Nombre":"El Rosal",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"57",
                    "I_Ubicacion":"25000"
                 },
                 {
                    "I_Codigo":"52256",
                    "C_Nombre":"EL ROSARIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5697",
                    "C_Nombre":"EL SANTUARIO ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52258",
                    "C_Nombre":"EL TABLON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19256",
                    "C_Nombre":"EL TAMBO CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52260",
                    "C_Nombre":"EL TAMBO NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54261",
                    "C_Nombre":"EL ZULIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41244",
                    "C_Nombre":"ELIAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68264",
                    "C_Nombre":"ENCINO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68266",
                    "C_Nombre":"ENCISO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"11265",
                    "C_Nombre":"ENGATIVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5264",
                    "C_Nombre":"ENTRERRIOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5266",
                    "C_Nombre":"ENVIGADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"1",
                    "C_Nombre":"error",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"245",
                    "C_Nombre":"ESPAÑA",
                    "I_Nivel":"1",
                    "I_Ind_Nal":"245",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73268",
                    "C_Nombre":"ESPINAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"249",
                    "C_Nombre":"ESTADOS UNIDOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25269",
                    "C_Nombre":"FACATATIVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73270",
                    "C_Nombre":"FALAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17272",
                    "C_Nombre":"FILADELFIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63272",
                    "C_Nombre":"FILANDIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15272",
                    "C_Nombre":"FIRAVITOBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73275",
                    "C_Nombre":"FLANDES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18001",
                    "C_Nombre":"FLORENCIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15276",
                    "C_Nombre":"FLORESTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68671",
                    "C_Nombre":"FLORIAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76275",
                    "C_Nombre":"FLORIDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68276",
                    "C_Nombre":"FLORIDABLAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25279",
                    "C_Nombre":"FOMEQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99766",
                    "C_Nombre":"FONSECA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"44001"
                 },
                 {
                    "I_Codigo":"44279",
                    "C_Nombre":"FONSECA GUAJIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25281",
                    "C_Nombre":"FOSCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52520",
                    "C_Nombre":"FRANCISCO P",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5282",
                    "C_Nombre":"FREDONIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73283",
                    "C_Nombre":"FRESNO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5284",
                    "C_Nombre":"FRONTINO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50287",
                    "C_Nombre":"FUENTE DE ORO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47288",
                    "C_Nombre":"FUNDACION",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52287",
                    "C_Nombre":"FUNES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25286",
                    "C_Nombre":"FUNZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25288",
                    "C_Nombre":"FUQUENE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25290",
                    "C_Nombre":"FUSAGASUGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25293",
                    "C_Nombre":"GACHALA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25295",
                    "C_Nombre":"GACHANCIPA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15293",
                    "C_Nombre":"GACHANTIVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25297",
                    "C_Nombre":"GACHETA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68296",
                    "C_Nombre":"GALAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8296",
                    "C_Nombre":"GALAPA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70235",
                    "C_Nombre":"GALERAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25299",
                    "C_Nombre":"GAMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20295",
                    "C_Nombre":"GAMARRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68298",
                    "C_Nombre":"GAMBITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15296",
                    "C_Nombre":"GAMEZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15299",
                    "C_Nombre":"GARAGOA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41298",
                    "C_Nombre":"GARZON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"11279",
                    "C_Nombre":"GAUCHAQUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63302",
                    "C_Nombre":"GENOVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18303",
                    "C_Nombre":"GETUCHA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41306",
                    "C_Nombre":"GIGANTE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76306",
                    "C_Nombre":"GINEBRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5306",
                    "C_Nombre":"GIRALDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25307",
                    "C_Nombre":"GIRARDOT",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5308",
                    "C_Nombre":"GIRARDOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99777",
                    "C_Nombre":"GIRON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"51",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"19698"
                 },
                 {
                    "I_Codigo":"68307",
                    "C_Nombre":"GIRON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5310",
                    "C_Nombre":"GOMEZ PLATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20310",
                    "C_Nombre":"GONZALEZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54313",
                    "C_Nombre":"GRAMALOTE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5313",
                    "C_Nombre":"GRANADA ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50313",
                    "C_Nombre":"GRANADA META",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68318",
                    "C_Nombre":"GUACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15317",
                    "C_Nombre":"GUACAMAYAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76318",
                    "C_Nombre":"GUACARI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25317",
                    "C_Nombre":"GUACHETA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52317",
                    "C_Nombre":"GUACHUCAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5315",
                    "C_Nombre":"GUADALUPE ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41319",
                    "C_Nombre":"GUADALUPE HUILA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68320",
                    "C_Nombre":"GUADALUPE SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25320",
                    "C_Nombre":"GUADUAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52320",
                    "C_Nombre":"GUAITARILLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52323",
                    "C_Nombre":"GUALMATAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47318",
                    "C_Nombre":"GUAMAL MAGDALENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50318",
                    "C_Nombre":"GUAMAL META",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73319",
                    "C_Nombre":"GUAMO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19318",
                    "C_Nombre":"GUAPI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68322",
                    "C_Nombre":"GUAPOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5318",
                    "C_Nombre":"GUARNE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25322",
                    "C_Nombre":"GUASCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5321",
                    "C_Nombre":"GUATAPE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25324",
                    "C_Nombre":"GUATAQUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25326",
                    "C_Nombre":"GUATAVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15322",
                    "C_Nombre":"GUATEQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66318",
                    "C_Nombre":"GUATICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68324",
                    "C_Nombre":"GUAVATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25328",
                    "C_Nombre":"GUAYABAL SI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25335",
                    "C_Nombre":"GUAYABETAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15325",
                    "C_Nombre":"GUAYATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68327",
                    "C_Nombre":"GUEPSA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15332",
                    "C_Nombre":"GUICAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25339",
                    "C_Nombre":"GUTIERREZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54344",
                    "C_Nombre":"HACARI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68344",
                    "C_Nombre":"HATO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85125",
                    "C_Nombre":"HATO COROZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99762",
                    "C_Nombre":"HATONUEVO",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"99765",
                    "C_Nombre":"HATONUEVO",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"44001"
                 },
                 {
                    "I_Codigo":"44378",
                    "C_Nombre":"HATONUEVO LA GUAJIRA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5347",
                    "C_Nombre":"HELICONIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54347",
                    "C_Nombre":"HERRAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73347",
                    "C_Nombre":"HERVEO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5353",
                    "C_Nombre":"HISPANIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41349",
                    "C_Nombre":"HOBO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99774",
                    "C_Nombre":"HOLLYWOOD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"",
                    "I_Ind_Internal":"",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73349",
                    "C_Nombre":"HONDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41000",
                    "C_Nombre":"HUILA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73001",
                    "C_Nombre":"IBAGUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73352",
                    "C_Nombre":"ICONONZO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52352",
                    "C_Nombre":"ILES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52354",
                    "C_Nombre":"IMUES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"94001",
                    "C_Nombre":"INIRIDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86000",
                    "C_Nombre":"INTENDENCIA         ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"81000",
                    "C_Nombre":"INTENDENCIA         ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"88000",
                    "C_Nombre":"INTEND.SAN          ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"19355",
                    "C_Nombre":"INZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52356",
                    "C_Nombre":"IPIALES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41357",
                    "C_Nombre":"IQUIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"88001",
                    "C_Nombre":"ISLA SAN ANDRES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41359",
                    "C_Nombre":"ISNOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27361",
                    "C_Nombre":"ISTMINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5360",
                    "C_Nombre":"ITAGUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5361",
                    "C_Nombre":"ITUANGO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15362",
                    "C_Nombre":"IZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19364",
                    "C_Nombre":"JAMBALO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76364",
                    "C_Nombre":"JAMUNDI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5364",
                    "C_Nombre":"JARDIN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15367",
                    "C_Nombre":"JENESANO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5368",
                    "C_Nombre":"JERICÓ ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15368",
                    "C_Nombre":"JERICÓ BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25368",
                    "C_Nombre":"JERUSALEN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68368",
                    "C_Nombre":"JESUS MARIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68370",
                    "C_Nombre":"JORDAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8372",
                    "C_Nombre":"JUAN DE ACO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25372",
                    "C_Nombre":"JUNIN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27372",
                    "C_Nombre":"JURADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20400",
                    "C_Nombre":"L. JAGUA IB",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41378",
                    "C_Nombre":"LA ARGENTIN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68377",
                    "C_Nombre":"LA BELLEZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25377",
                    "C_Nombre":"LA CALERA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15380",
                    "C_Nombre":"LA CAPILLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5376",
                    "C_Nombre":"LA CEJA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66383",
                    "C_Nombre":"LA CELIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91405",
                    "C_Nombre":"LA CHORRERA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52378",
                    "C_Nombre":"LA CRUZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76377",
                    "C_Nombre":"LA CUMBRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17380",
                    "C_Nombre":"LA DORADA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5380",
                    "C_Nombre":"LA ESTRELLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52381",
                    "C_Nombre":"LA FLORIDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20383",
                    "C_Nombre":"LA GLORIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44000",
                    "C_Nombre":"LA GUAJIRA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50350",
                    "C_Nombre":"LA MACARENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5387",
                    "C_Nombre":"LA MAGDALEN         ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"17388",
                    "C_Nombre":"LA MERCED",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25386",
                    "C_Nombre":"LA MESA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18410",
                    "C_Nombre":"LA MONTAYIT",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25394",
                    "C_Nombre":"LA PALMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68397",
                    "C_Nombre":"LA PAZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91407",
                    "C_Nombre":"LA PEDRERA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25398",
                    "C_Nombre":"LA PENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41396",
                    "C_Nombre":"LA PLATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54398",
                    "C_Nombre":"LA PLAYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99524",
                    "C_Nombre":"LA PRIMAVER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85136",
                    "C_Nombre":"LA SALINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19392",
                    "C_Nombre":"LA SIERRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63401",
                    "C_Nombre":"LA TEBAIDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5400",
                    "C_Nombre":"LA UNION ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52399",
                    "C_Nombre":"LA UNION NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70400",
                    "C_Nombre":"LA UNION SUCRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76400",
                    "C_Nombre":"LA UNION VALLE DEL CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15403",
                    "C_Nombre":"LA UVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19397",
                    "C_Nombre":"LA VEGA CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25402",
                    "C_Nombre":"LA VEGA CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15401",
                    "C_Nombre":"LA VICTORIA BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76403",
                    "C_Nombre":"LA VICTORIA VALLE DEL CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66400",
                    "C_Nombre":"LA VIRGINIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54377",
                    "C_Nombre":"LABATECA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15377",
                    "C_Nombre":"LABRANZAGRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68385",
                    "C_Nombre":"LANDAZURI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68406",
                    "C_Nombre":"LEBRIJA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52405",
                    "C_Nombre":"LEIVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50400",
                    "C_Nombre":"LEJANIAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25407",
                    "C_Nombre":"LENGUAZAQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73408",
                    "C_Nombre":"LERIDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91001",
                    "C_Nombre":"LETICIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73411",
                    "C_Nombre":"LIBANO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5411",
                    "C_Nombre":"LIBORINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52411",
                    "C_Nombre":"LINARES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99782",
                    "C_Nombre":"LITORAL DE SAN JUAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"51",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"27000"
                 },
                 {
                    "I_Codigo":"27413",
                    "C_Nombre":"LLORO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19418",
                    "C_Nombre":"LOPEZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23417",
                    "C_Nombre":"LORICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52418",
                    "C_Nombre":"LOS ANDES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23419",
                    "C_Nombre":"LOS CORDOBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70418",
                    "C_Nombre":"LOS PALMITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68418",
                    "C_Nombre":"LOS SANTOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54418",
                    "C_Nombre":"LOURDES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8421",
                    "C_Nombre":"LURUACO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15425",
                    "C_Nombre":"MACANAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68425",
                    "C_Nombre":"MACARAVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5425",
                    "C_Nombre":"MACEO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25426",
                    "C_Nombre":"MACHETA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25430",
                    "C_Nombre":"MADRID",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13430",
                    "C_Nombre":"MAGANGUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47000",
                    "C_Nombre":"MAGDALENA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52427",
                    "C_Nombre":"MAGUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13433",
                    "C_Nombre":"MAHATES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44430",
                    "C_Nombre":"MAICAO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70429",
                    "C_Nombre":"MAJAGUAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68432",
                    "C_Nombre":"MALAGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8433",
                    "C_Nombre":"MALAMBO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52435",
                    "C_Nombre":"MALLAMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8436",
                    "C_Nombre":"MANATI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44560",
                    "C_Nombre":"MANAURE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20443",
                    "C_Nombre":"MANAURE B.",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85139",
                    "C_Nombre":"MANI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17001",
                    "C_Nombre":"MANIZALES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25436",
                    "C_Nombre":"MANTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17433",
                    "C_Nombre":"MANZANARES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50325",
                    "C_Nombre":"MAPIRIPAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13440",
                    "C_Nombre":"MARGARITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13442",
                    "C_Nombre":"MARIA LA BA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5440",
                    "C_Nombre":"MARINILLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15442",
                    "C_Nombre":"MARIPI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73443",
                    "C_Nombre":"MARIQUITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17442",
                    "C_Nombre":"MARMATO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17444",
                    "C_Nombre":"MARQUETALIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66440",
                    "C_Nombre":"MARSELLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17446",
                    "C_Nombre":"MARULANDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68444",
                    "C_Nombre":"MATANZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18449",
                    "C_Nombre":"MATICURU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5001",
                    "C_Nombre":"MEDELLIN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25438",
                    "C_Nombre":"MEDINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73449",
                    "C_Nombre":"MELGAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19450",
                    "C_Nombre":"MERCADERES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50330",
                    "C_Nombre":"MESETAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50000",
                    "C_Nombre":"META",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18460",
                    "C_Nombre":"MILAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99770",
                    "C_Nombre":"Milan-Italia",
                    "I_Nivel":"1",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"15455",
                    "C_Nombre":"MIRAFLORES BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"95200",
                    "C_Nombre":"MIRAFLORES GUAVIARE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19455",
                    "C_Nombre":"MIRANDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91460",
                    "C_Nombre":"MIRITI-PARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66456",
                    "C_Nombre":"MISTRATO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"97001",
                    "C_Nombre":"MITU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86001",
                    "C_Nombre":"MOCOA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68464",
                    "C_Nombre":"MOGOTES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68468",
                    "C_Nombre":"MOLAGAVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23464",
                    "C_Nombre":"MOMIL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13468",
                    "C_Nombre":"MOMPOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15464",
                    "C_Nombre":"MONGUA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15466",
                    "C_Nombre":"MONGUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15469",
                    "C_Nombre":"MONIQUIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23500",
                    "C_Nombre":"MONITOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5467",
                    "C_Nombre":"MONTEBELLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23466",
                    "C_Nombre":"MONTELIBANO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63470",
                    "C_Nombre":"MONTENEGRO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23001",
                    "C_Nombre":"MONTERIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85162",
                    "C_Nombre":"MONTERREY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13473",
                    "C_Nombre":"MORALES BOLIVAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19473",
                    "C_Nombre":"MORALES CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18479",
                    "C_Nombre":"MORELIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"95220",
                    "C_Nombre":"MORICHAL            ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"70473",
                    "C_Nombre":"MORROA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25473",
                    "C_Nombre":"MOSQUERA CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52473",
                    "C_Nombre":"MOSQUERA NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15476",
                    "C_Nombre":"MOTAVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99779",
                    "C_Nombre":"Motiscua",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"51",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"54000"
                 },
                 {
                    "I_Codigo":"5475",
                    "C_Nombre":"MURINDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5480",
                    "C_Nombre":"MUTATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54480",
                    "C_Nombre":"MUTISCUA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15480",
                    "C_Nombre":"MUZO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5483",
                    "C_Nombre":"NARIÑO ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25483",
                    "C_Nombre":"NARIÑO CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41483",
                    "C_Nombre":"NATAGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73483",
                    "C_Nombre":"NATAGAIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5495",
                    "C_Nombre":"NECHI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5490",
                    "C_Nombre":"NECOCLI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17486",
                    "C_Nombre":"NEIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41001",
                    "C_Nombre":"NEIVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25486",
                    "C_Nombre":"NEMOCON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25488",
                    "C_Nombre":"NILO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25489",
                    "C_Nombre":"NIMAIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15491",
                    "C_Nombre":"NOBSA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25491",
                    "C_Nombre":"NOCAIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54000",
                    "C_Nombre":"NORTE DE SA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27491",
                    "C_Nombre":"NOVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99496",
                    "C_Nombre":"NUEVA ANTIO         ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"15494",
                    "C_Nombre":"NUEVO COLON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85225",
                    "C_Nombre":"NUNCHIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27495",
                    "C_Nombre":"NUQUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76497",
                    "C_Nombre":"OBANDO VALLE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68498",
                    "C_Nombre":"OCAMONTE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54498",
                    "C_Nombre":"OCANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68500",
                    "C_Nombre":"OIBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15500",
                    "C_Nombre":"OICATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5501",
                    "C_Nombre":"OLAYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52490",
                    "C_Nombre":"OLAYA HERRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68502",
                    "C_Nombre":"ONZAGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41503",
                    "C_Nombre":"OPORAPA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86320",
                    "C_Nombre":"ORITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85230",
                    "C_Nombre":"OROCUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73504",
                    "C_Nombre":"ORTEGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52506",
                    "C_Nombre":"OSPINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25506",
                    "C_Nombre":"OSPINA PERE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15507",
                    "C_Nombre":"OTANCHE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70508",
                    "C_Nombre":"OVEJAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15511",
                    "C_Nombre":"PACHAVITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25513",
                    "C_Nombre":"PACHO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"97511",
                    "C_Nombre":"PACOA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17513",
                    "C_Nombre":"PACORA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19513",
                    "C_Nombre":"PADILLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15514",
                    "C_Nombre":"PAEZ BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19517",
                    "C_Nombre":"PAEZ CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41518",
                    "C_Nombre":"PAICOL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20517",
                    "C_Nombre":"PAILITAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25518",
                    "C_Nombre":"PAIME",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15516",
                    "C_Nombre":"PAIPA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15518",
                    "C_Nombre":"PAJARITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41524",
                    "C_Nombre":"PALERMO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17524",
                    "C_Nombre":"PALESTINA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68522",
                    "C_Nombre":"PALMAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8520",
                    "C_Nombre":"PALMAR DE V",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68524",
                    "C_Nombre":"PALMAS SOCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76520",
                    "C_Nombre":"PALMIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70523",
                    "C_Nombre":"PALMITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54518",
                    "C_Nombre":"PAMPLONA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54520",
                    "C_Nombre":"PAMPLONITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25524",
                    "C_Nombre":"PANDI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15522",
                    "C_Nombre":"PANQUEBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68533",
                    "C_Nombre":"PARAMO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25530",
                    "C_Nombre":"PARATEBUENO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25535",
                    "C_Nombre":"PASCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52001",
                    "C_Nombre":"PASTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19532",
                    "C_Nombre":"PATIA (EL B",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15531",
                    "C_Nombre":"PAUNA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15533",
                    "C_Nombre":"PAYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85250",
                    "C_Nombre":"PAZ DE ARIP",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15537",
                    "C_Nombre":"PAZ DE RIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47541",
                    "C_Nombre":"PEDRAZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20550",
                    "C_Nombre":"PELAYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5541",
                    "C_Nombre":"PENOL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17541",
                    "C_Nombre":"PENSILVANIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5543",
                    "C_Nombre":"PEQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66001",
                    "C_Nombre":"PEREIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15542",
                    "C_Nombre":"PESCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68547",
                    "C_Nombre":"PIEDECUESTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73547",
                    "C_Nombre":"PIEDRAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19548",
                    "C_Nombre":"PIENDAMO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63548",
                    "C_Nombre":"PIJAO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68549",
                    "C_Nombre":"PINCHOTE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13549",
                    "C_Nombre":"PINILLOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8549",
                    "C_Nombre":"PIOJO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15550",
                    "C_Nombre":"PISVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41548",
                    "C_Nombre":"PITAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41551",
                    "C_Nombre":"PITALITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47551",
                    "C_Nombre":"PIVIJAY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73555",
                    "C_Nombre":"PLANADAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23555",
                    "C_Nombre":"PLANETA RIC",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47555",
                    "C_Nombre":"PLATO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52540",
                    "C_Nombre":"POLICARPA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8558",
                    "C_Nombre":"POLONUEVO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8560",
                    "C_Nombre":"PONEDERA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19001",
                    "C_Nombre":"POPAYAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85263",
                    "C_Nombre":"PORE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52560",
                    "C_Nombre":"POTOSI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76563",
                    "C_Nombre":"PRADERA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73563",
                    "C_Nombre":"PRADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"88564",
                    "C_Nombre":"PROVIDENCIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5585",
                    "C_Nombre":"PTO. NARE L",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23570",
                    "C_Nombre":"PUEBLO NUEV",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66572",
                    "C_Nombre":"PUEBLO RICO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47570",
                    "C_Nombre":"PUEBLO VIEJ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5576",
                    "C_Nombre":"PUEBLORRICO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68572",
                    "C_Nombre":"PUENTE NACI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52573",
                    "C_Nombre":"PUERRES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86568",
                    "C_Nombre":"PUERTO ASIS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5579",
                    "C_Nombre":"PUERTO BERR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99761",
                    "C_Nombre":"PUERTO BOLIVAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"15572",
                    "C_Nombre":"PUERTO BOYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99001",
                    "C_Nombre":"PUERTO CARR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8573",
                    "C_Nombre":"PUERTO COLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23574",
                    "C_Nombre":"PUERTO ESCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50568",
                    "C_Nombre":"PUERTO GAIT",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86573",
                    "C_Nombre":"PUERTO LEGU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23580",
                    "C_Nombre":"PUERTO LIBE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50577",
                    "C_Nombre":"PUERTO LLER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50573",
                    "C_Nombre":"PUERTO LOPE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99569",
                    "C_Nombre":"PUERTO MURI         ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"91540",
                    "C_Nombre":"PUERTO NARI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68573",
                    "C_Nombre":"PUERTO PARR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18592",
                    "C_Nombre":"PUERTO RICO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"81591",
                    "C_Nombre":"PUERTO ROND",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25572",
                    "C_Nombre":"PUERTO SALG",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91669",
                    "C_Nombre":"PUERTO SANT",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19573",
                    "C_Nombre":"PUERTO TEJA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5591",
                    "C_Nombre":"PUERTO TRIU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68575",
                    "C_Nombre":"PUERTO WILC",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25580",
                    "C_Nombre":"PULI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52585",
                    "C_Nombre":"PUPIALES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19585",
                    "C_Nombre":"PURACE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73585",
                    "C_Nombre":"PURIFICACIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23586",
                    "C_Nombre":"PURISIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25592",
                    "C_Nombre":"QUEBRADANEG",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25594",
                    "C_Nombre":"QUETAME",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27001",
                    "C_Nombre":"QUIBDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63594",
                    "C_Nombre":"QUIMBAYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66594",
                    "C_Nombre":"QUINCHIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63000",
                    "C_Nombre":"QUINDIO",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25596",
                    "C_Nombre":"QUIPILE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99771",
                    "C_Nombre":"QUITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"593",
                    "I_Ind_Internal":"2",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"25599",
                    "C_Nombre":"RAFAEL REYE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54599",
                    "C_Nombre":"RAGONVALIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15599",
                    "C_Nombre":"RAMIRIQUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15600",
                    "C_Nombre":"RAQUIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85279",
                    "C_Nombre":"RECETOR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"628",
                    "C_Nombre":"REINO UNIDO-INGLATERRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5604",
                    "C_Nombre":"REMEDIOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47605",
                    "C_Nombre":"REMOLINO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8606",
                    "C_Nombre":"REPELON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50606",
                    "C_Nombre":"RESTREPO META",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76606",
                    "C_Nombre":"RESTREPO VALLE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5607",
                    "C_Nombre":"RETIRO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25612",
                    "C_Nombre":"RICAURTE CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52612",
                    "C_Nombre":"RICAURTE NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20614",
                    "C_Nombre":"RIO DE ORO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13600",
                    "C_Nombre":"RIO VIEJO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73616",
                    "C_Nombre":"RIOBLANCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76616",
                    "C_Nombre":"RIOFRIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44001",
                    "C_Nombre":"RIOHACHA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5615",
                    "C_Nombre":"RIONEGRO ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68615",
                    "C_Nombre":"RIONEGRO SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17614",
                    "C_Nombre":"RIOSUCIO CALDAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27615",
                    "C_Nombre":"RIOSUCIO CHOCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66000",
                    "C_Nombre":"RISARALDA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17616",
                    "C_Nombre":"RISARALDA CALDAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41615",
                    "C_Nombre":"RIVERA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52621",
                    "C_Nombre":"ROBERTO PAY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20621",
                    "C_Nombre":"ROBLES LA P",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76622",
                    "C_Nombre":"ROLDANILLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73622",
                    "C_Nombre":"RONCESVALLE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15621",
                    "C_Nombre":"RONDON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19622",
                    "C_Nombre":"ROSAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73624",
                    "C_Nombre":"ROVIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68655",
                    "C_Nombre":"SABANA DE T",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8634",
                    "C_Nombre":"SABANAGRAND",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5628",
                    "C_Nombre":"SABANALARGA ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8638",
                    "C_Nombre":"SABANALARGA ATLANTICO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85300",
                    "C_Nombre":"SABANALARGA CASANARE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5631",
                    "C_Nombre":"SABANETA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15632",
                    "C_Nombre":"SABOYA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85315",
                    "C_Nombre":"SACAMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15638",
                    "C_Nombre":"SACHICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23660",
                    "C_Nombre":"SAHAGUN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41660",
                    "C_Nombre":"SALADOBLANC",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17653",
                    "C_Nombre":"SALAMINA CALDAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47675",
                    "C_Nombre":"SALAMINA MAGDALENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54660",
                    "C_Nombre":"SALAZAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73671",
                    "C_Nombre":"SALDANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"63690",
                    "C_Nombre":"SALENTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5642",
                    "C_Nombre":"SALGAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15646",
                    "C_Nombre":"SAMACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17662",
                    "C_Nombre":"SAMANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52678",
                    "C_Nombre":"SAMANIEGO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70670",
                    "C_Nombre":"SAMPUES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41668",
                    "C_Nombre":"SAN AGUSTIN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20710",
                    "C_Nombre":"SAN ALBERTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68669",
                    "C_Nombre":"SAN ANDRES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5647",
                    "C_Nombre":"SAN ANDRES DE CUERQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23670",
                    "C_Nombre":"SAN ANDRES SOTAVENTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23672",
                    "C_Nombre":"SAN ANTERO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25645",
                    "C_Nombre":"SAN ANTONIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73675",
                    "C_Nombre":"SAN ANTONIO TOLIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70678",
                    "C_Nombre":"SAN BENITO ABAD SUCRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68673",
                    "C_Nombre":"SAN BENITO SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23675",
                    "C_Nombre":"SAN BERNARD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25649",
                    "C_Nombre":"SAN BERNARDO CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54670",
                    "C_Nombre":"SAN CALIXTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5649",
                    "C_Nombre":"SAN CARLOS ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23678",
                    "C_Nombre":"SAN CARLOS CORDOBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50680",
                    "C_Nombre":"SAN CARLOS DE GUAROA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25653",
                    "C_Nombre":"SAN CAYETANO CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54673",
                    "C_Nombre":"SAN CAYETANO NORTE DE SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20750",
                    "C_Nombre":"SAN DIEGO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15660",
                    "C_Nombre":"SAN EDUARDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13647",
                    "C_Nombre":"SAN ESTANIS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13650",
                    "C_Nombre":"SAN FERNAND",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25658",
                    "C_Nombre":"SAN FRANCISCO CUNDINAMARCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86755",
                    "C_Nombre":"SAN FRANCISCO PUTUMAYO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68679",
                    "C_Nombre":"SAN GIL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13654",
                    "C_Nombre":"SAN JACINTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5656",
                    "C_Nombre":"SAN JERONIM",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68682",
                    "C_Nombre":"SAN JOAQUIN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99760",
                    "C_Nombre":"SAN JOSE DE         ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"5658",
                    "C_Nombre":"SAN JOSE DE LA MONTAÑA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68684",
                    "C_Nombre":"SAN JOSE DE MIRANDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15664",
                    "C_Nombre":"SAN JOSE DE PARE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"95001",
                    "C_Nombre":"SAN JOSE DEL GUAVIARE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27660",
                    "C_Nombre":"SAN JOSE DEL PALMAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50683",
                    "C_Nombre":"SAN JUAN DE ARAMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70702",
                    "C_Nombre":"SAN JUAN DE BETULIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25662",
                    "C_Nombre":"SAN JUAN DE RIO SECO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44650",
                    "C_Nombre":"SAN JUAN DEL CESAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13657",
                    "C_Nombre":"SAN JUAN NEPOMUCENO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50686",
                    "C_Nombre":"SAN JUANITO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52687",
                    "C_Nombre":"SAN LORENZO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5660",
                    "C_Nombre":"SAN LUIS ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15667",
                    "C_Nombre":"SAN LUIS DE GACENO BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85325",
                    "C_Nombre":"SAN LUIS DE PALENQUE CASANARE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73678",
                    "C_Nombre":"SAN LUIS TOLIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70708",
                    "C_Nombre":"SAN MARCOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20770",
                    "C_Nombre":"SAN MARTIN CESAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13667",
                    "C_Nombre":"SAN MARTIN DE LOBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50689",
                    "C_Nombre":"SAN MARTIN META",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15673",
                    "C_Nombre":"SAN MATEO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15676",
                    "C_Nombre":"SAN MIGUEL DE SEMA BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68686",
                    "C_Nombre":"SAN MIGUEL SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70713",
                    "C_Nombre":"SAN ONOFRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13670",
                    "C_Nombre":"SAN PABLO BOLIVAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15681",
                    "C_Nombre":"SAN PABLO DE BORBUR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52693",
                    "C_Nombre":"SAN PABLO NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5664",
                    "C_Nombre":"SAN PEDRO ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5665",
                    "C_Nombre":"SAN PEDRO DE URABA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70717",
                    "C_Nombre":"SAN PEDRO SUCRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76670",
                    "C_Nombre":"SAN PEDRO VALLE DEL CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23686",
                    "C_Nombre":"SAN PELAYO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5667",
                    "C_Nombre":"SAN RAFAEL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5670",
                    "C_Nombre":"SAN ROQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19693",
                    "C_Nombre":"SAN SEBASTIÁN CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47692",
                    "C_Nombre":"SAN SEBASTIAN DE BUENAVISTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5674",
                    "C_Nombre":"SAN VICENTE ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68689",
                    "C_Nombre":"SAN VICENTE DE CHUCURI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18753",
                    "C_Nombre":"SAN VICENTE DEL CAGUAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47703",
                    "C_Nombre":"SAN ZENON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52683",
                    "C_Nombre":"SANDONA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47707",
                    "C_Nombre":"SANTA ANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5679",
                    "C_Nombre":"SANTA BARBARA ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52696",
                    "C_Nombre":"SANTA BARBARA NARIÑO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68705",
                    "C_Nombre":"SANTA BARBARA SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13673",
                    "C_Nombre":"SANTA CATAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68720",
                    "C_Nombre":"SANTA HELEN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73686",
                    "C_Nombre":"SANTA ISABE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8675",
                    "C_Nombre":"SANTA LUCIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15690",
                    "C_Nombre":"SANTA MARIA BOYACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41676",
                    "C_Nombre":"SANTA MARIA HUILA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47001",
                    "C_Nombre":"SANTA MARTA MAGDALENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99572",
                    "C_Nombre":"SANTA RITA          ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"13683",
                    "C_Nombre":"SANTA ROSA BOLIVAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19701",
                    "C_Nombre":"SANTA ROSA CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66682",
                    "C_Nombre":"SANTA ROSA DE CABAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5686",
                    "C_Nombre":"SANTA ROSA DE OSOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15693",
                    "C_Nombre":"SANTA ROSA DE VITERBO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99666",
                    "C_Nombre":"SANTA ROSAL         ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"99624",
                    "C_Nombre":"SANTA ROSALIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15696",
                    "C_Nombre":"SANTA SOFIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52699",
                    "C_Nombre":"SANTACRUZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5042",
                    "C_Nombre":"SANTAFE DE ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15686",
                    "C_Nombre":"SANTANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68000",
                    "C_Nombre":"SANTANDER",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19698",
                    "C_Nombre":"SANTANDER DE QUILICHAO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54680",
                    "C_Nombre":"SANTIAGO N. DE SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86760",
                    "C_Nombre":"SANTIAGO PUTUMAYO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5690",
                    "C_Nombre":"SANTO DOMIN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8685",
                    "C_Nombre":"SANTO TOMAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"66687",
                    "C_Nombre":"SANTUARIO RISARALDA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52720",
                    "C_Nombre":"SAPUYES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"81736",
                    "C_Nombre":"SARAVENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54720",
                    "C_Nombre":"SARDINATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25718",
                    "C_Nombre":"SASAIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15720",
                    "C_Nombre":"SATIVANORTE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15723",
                    "C_Nombre":"SATIVASUR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5736",
                    "C_Nombre":"SEGOVIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25736",
                    "C_Nombre":"SESQUILE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76736",
                    "C_Nombre":"SEVILLA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15740",
                    "C_Nombre":"SIACHOQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25740",
                    "C_Nombre":"SIBATE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86749",
                    "C_Nombre":"SIBUNDOY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54743",
                    "C_Nombre":"SILOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25743",
                    "C_Nombre":"SILVANIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19743",
                    "C_Nombre":"SILVIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68745",
                    "C_Nombre":"SIMACOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25745",
                    "C_Nombre":"SIMIJACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13744",
                    "C_Nombre":"SIMITI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70742",
                    "C_Nombre":"SINCE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70001",
                    "C_Nombre":"SINCELEJO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"70000",
                    "I_Ind_Internal":"1",
                    "I_Ubicacion":"70000"
                 },
                 {
                    "I_Codigo":"27745",
                    "C_Nombre":"SIPI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47745",
                    "C_Nombre":"SITIONUEVO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25754",
                    "C_Nombre":"SOACHA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15753",
                    "C_Nombre":"SOATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15757",
                    "C_Nombre":"SOCHA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68755",
                    "C_Nombre":"SOCORRO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15755",
                    "C_Nombre":"SOCOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15759",
                    "C_Nombre":"SOGAMOSO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18765",
                    "C_Nombre":"SOLANO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8758",
                    "C_Nombre":"SOLEDAD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18767",
                    "C_Nombre":"SOLITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15761",
                    "C_Nombre":"SOMONDOCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5756",
                    "C_Nombre":"SONSON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5761",
                    "C_Nombre":"SOPETRAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13760",
                    "C_Nombre":"SOPLAVIENTO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25758",
                    "C_Nombre":"SOPO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15762",
                    "C_Nombre":"SORA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15764",
                    "C_Nombre":"SORACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15763",
                    "C_Nombre":"SOTAQUIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19760",
                    "C_Nombre":"SOTARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68770",
                    "C_Nombre":"SUAITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73770",
                    "C_Nombre":"SUAREZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41770",
                    "C_Nombre":"SUAZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"11769",
                    "C_Nombre":"SUBA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25769",
                    "C_Nombre":"SUBACHOQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70771",
                    "C_Nombre":"SUCRE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70000",
                    "C_Nombre":"SUCRE               ",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"68773",
                    "C_Nombre":"SUCRE SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25772",
                    "C_Nombre":"SUESCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25777",
                    "C_Nombre":"SUPATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17777",
                    "C_Nombre":"SUPIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68780",
                    "C_Nombre":"SURATA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25779",
                    "C_Nombre":"SUSA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15774",
                    "C_Nombre":"SUSACON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15776",
                    "C_Nombre":"SUTAMARCHAN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25781",
                    "C_Nombre":"SUTATAUSA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15778",
                    "C_Nombre":"SUTATENZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25785",
                    "C_Nombre":"TABIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27787",
                    "C_Nombre":"TADO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20787",
                    "C_Nombre":"TAMALAMEQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85400",
                    "C_Nombre":"TAMARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"81794",
                    "C_Nombre":"TAME ARAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5789",
                    "C_Nombre":"TAMESIS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52786",
                    "C_Nombre":"TAMINANGO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52788",
                    "C_Nombre":"TANGUA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"91798",
                    "C_Nombre":"TARAPACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5790",
                    "C_Nombre":"TARAZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41791",
                    "C_Nombre":"TARQUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5792",
                    "C_Nombre":"TARSO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15790",
                    "C_Nombre":"TASCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85410",
                    "C_Nombre":"TAURAMENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25793",
                    "C_Nombre":"TAUSA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41799",
                    "C_Nombre":"TELLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25797",
                    "C_Nombre":"TENA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"47798",
                    "C_Nombre":"TENERIFE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25799",
                    "C_Nombre":"TENJO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15798",
                    "C_Nombre":"TENZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54800",
                    "C_Nombre":"TEORAMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41801",
                    "C_Nombre":"TERUEL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41797",
                    "C_Nombre":"TESALIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25805",
                    "C_Nombre":"TIBACUY",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15804",
                    "C_Nombre":"TIBANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15806",
                    "C_Nombre":"TIBASOSA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25807",
                    "C_Nombre":"TIBIRITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54810",
                    "C_Nombre":"TIBU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23807",
                    "C_Nombre":"TIERRALTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41807",
                    "C_Nombre":"TIMANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19807",
                    "C_Nombre":"TIMBIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19809",
                    "C_Nombre":"TIMBIQUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15808",
                    "C_Nombre":"TINJACA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15810",
                    "C_Nombre":"TIPACOQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5809",
                    "C_Nombre":"TITIRIBI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15814",
                    "C_Nombre":"TOCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25815",
                    "C_Nombre":"TOCAIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25817",
                    "C_Nombre":"TOCANCIPA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15816",
                    "C_Nombre":"TOGUI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5819",
                    "C_Nombre":"TOLEDO ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54820",
                    "C_Nombre":"TOLEDO NORTE DE SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73000",
                    "C_Nombre":"TOLIMA",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70820",
                    "C_Nombre":"TOLU",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"70823",
                    "C_Nombre":"TOLUVIEJO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68820",
                    "C_Nombre":"TONA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15820",
                    "C_Nombre":"TOPAGA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25823",
                    "C_Nombre":"TOPAIPI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19821",
                    "C_Nombre":"TORIBIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76823",
                    "C_Nombre":"TORO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15822",
                    "C_Nombre":"TOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"19824",
                    "C_Nombre":"TOTORO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85430",
                    "C_Nombre":"TRINIDAD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76828",
                    "C_Nombre":"TRUJILLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"8832",
                    "C_Nombre":"TUBARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76834",
                    "C_Nombre":"TULUÁ VALLE DEL CAUCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52835",
                    "C_Nombre":"TUMACO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15001",
                    "C_Nombre":"TUNJA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15832",
                    "C_Nombre":"TUNUNGUA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52838",
                    "C_Nombre":"TUQUERRES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13836",
                    "C_Nombre":"TURBACO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13838",
                    "C_Nombre":"TURBANA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5837",
                    "C_Nombre":"TURBO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15835",
                    "C_Nombre":"TURMEQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15837",
                    "C_Nombre":"TUTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15839",
                    "C_Nombre":"TUTASA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25839",
                    "C_Nombre":"UBALA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25841",
                    "C_Nombre":"UBAQUE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25843",
                    "C_Nombre":"UBATE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76845",
                    "C_Nombre":"ULLOA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15842",
                    "C_Nombre":"UMBITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68844",
                    "C_Nombre":"UMPALA              ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"25845",
                    "C_Nombre":"UNE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"27800",
                    "C_Nombre":"UNGUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5842",
                    "C_Nombre":"URAMITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44847",
                    "C_Nombre":"URIBIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5847",
                    "C_Nombre":"URRAO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44855",
                    "C_Nombre":"URUMITA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"11848",
                    "C_Nombre":"USAQUEN",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"11850",
                    "C_Nombre":"USME",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25851",
                    "C_Nombre":"UTICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5854",
                    "C_Nombre":"VALDIVIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"23855",
                    "C_Nombre":"VALENCIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76000",
                    "C_Nombre":"VALLE",
                    "I_Nivel":"2",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68855",
                    "C_Nombre":"VALLE DE SAN JOSÉ SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73854",
                    "C_Nombre":"VALLE DE SAN JUAN TOLIMA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"20001",
                    "C_Nombre":"VALLEDUPAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5856",
                    "C_Nombre":"VALPARAÍSO ANTIOQUIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"18860",
                    "C_Nombre":"VALPARAÍSO CAQUETA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5858",
                    "C_Nombre":"VEGACHI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68861",
                    "C_Nombre":"VELEZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73861",
                    "C_Nombre":"VENADILLO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5861",
                    "C_Nombre":"VENECIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99768",
                    "C_Nombre":"Venezuela",
                    "I_Nivel":"1",
                    "I_Ind_Nal":"",
                    "I_Ind_Internal":"",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15861",
                    "C_Nombre":"VENTAQUEMAD",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25862",
                    "C_Nombre":"VERGARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76863",
                    "C_Nombre":"VERSALLES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68867",
                    "C_Nombre":"VETAS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25867",
                    "C_Nombre":"VIANI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17867",
                    "C_Nombre":"VICTORIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"99783",
                    "C_Nombre":"Viena - Austria",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":"57"
                 },
                 {
                    "I_Codigo":"5873",
                    "C_Nombre":"VIGIA DEL F",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76869",
                    "C_Nombre":"VIJES",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54871",
                    "C_Nombre":"VILLA CARO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15407",
                    "C_Nombre":"VILLA DE LEYVA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"86885",
                    "C_Nombre":"VILLA GARZON PUTUMAYO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"54874",
                    "C_Nombre":"VILLA ROSAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25871",
                    "C_Nombre":"VILLAGOMEZ",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73870",
                    "C_Nombre":"VILLAHERMOS",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17873",
                    "C_Nombre":"VILLAMARIA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13873",
                    "C_Nombre":"VILLANUEVA BOLIVAR",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85440",
                    "C_Nombre":"VILLANUEVA CASANARE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"44874",
                    "C_Nombre":"VILLANUEVA LA GUAJIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68872",
                    "C_Nombre":"VILLANUEVA SANTANDER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25873",
                    "C_Nombre":"VILLAPINZON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"73873",
                    "C_Nombre":"VILLARRICA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50001",
                    "C_Nombre":"VILLAVICENCIO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41872",
                    "C_Nombre":"VILLAVIEJA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25875",
                    "C_Nombre":"VILLETA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25878",
                    "C_Nombre":"VIOTA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15879",
                    "C_Nombre":"VIRACACHA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"50711",
                    "C_Nombre":"VISTA HERMO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"17877",
                    "C_Nombre":"VITERBO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25885",
                    "C_Nombre":"YACOPI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"52885",
                    "C_Nombre":"YACUANQUER",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"41885",
                    "C_Nombre":"YAGUARA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5885",
                    "C_Nombre":"YALI",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5887",
                    "C_Nombre":"YARUMAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"97889",
                    "C_Nombre":"YAVARATE",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5890",
                    "C_Nombre":"YOLOMBO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5893",
                    "C_Nombre":"YONDO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"85001",
                    "C_Nombre":"YOPAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76890",
                    "C_Nombre":"YOTOCO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76892",
                    "C_Nombre":"YUMBO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"13894",
                    "C_Nombre":"ZAMBRANO",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"68895",
                    "C_Nombre":"ZAPATOCA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"5895",
                    "C_Nombre":"ZARAGOZA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"76895",
                    "C_Nombre":"ZARZAL",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"15897",
                    "C_Nombre":"ZETAQUIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25898",
                    "C_Nombre":"ZIPACON",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 },
                 {
                    "I_Codigo":"25899",
                    "C_Nombre":"ZIPAQUIRA",
                    "I_Nivel":"3",
                    "I_Ind_Nal":"3",
                    "I_Ind_Internal":"3",
                    "I_Ubicacion":""
                 }
              ]
           }
        },
        "consultarPersonaCygnus_response":{
           "I_Codigo":"3040",
           "C_Tipo_Person":"N",
           "C_Identificacion":"1013643973",
           "I_Tipo_Iden":"1",
           "I_Ciudad_Exp":"11001",
           "C_Codigo_Asoc":"3040",
           "I_Tipo_Cliente":"1",
           "F_Fecha_Exp":"08/12/1997",
           "C_Retencion":"",
           "N_Distrib":"100",
           "C_Forma_Pago":"2",
           "I_Dia_Pago":"",
           "I_Vinculac_Lab":"7",
           "C_Direccion":"direccion pruebas desarrollo",
           "C_Telefono":"3006476345",
           "C_E_Mail":"andres.sierra@ebssas.com",
           "C_Clave":"4444",
           "C_Clave_Int":"4444",
           "F_Fec_Ingreso":"07/10/2018",
           "F_Fec_Ing_Emp":"09/01/1958",
           "I_Oficina":"1",
           "F_Fec_Sistema":"01/12/2002",
           "I_Acto_Ad":"1",
           "I_Tipo_Act_Ec":"3",
           "C_Asesor_Com":"",
           "I_Numveccod":"2",
           "C_Numactadm":"1",
           "C_Estado_Act":"A",
           "C_Apellidos":"SIERRA HORMIGA",
           "C_Nombres":"ANDRÉS CAMILO",
           "C_Sexo":"M",
           "F_Fec_Nacimiento":"13/04/1938",
           "I_Ciudad_Nac":"25286",
           "C_Estado_Civil":"S",
           "N_Salario":"781242",
           "I_Nivel_Estudio":"110",
           "I_Profesion":"1",
           "I_Cargo":"91",
           "I_Dependencia":"90501",
           "I_Pagaduria":"4",
           "I_Num_Per_Car":"0",
           "C_Residente":"0",
           "I_Ciudad_Res":"11001",
           "F_Fecha_Resid":"07/10/1983",
           "C_Aficion":"",
           "I_Tipo_Cont":"9",
           "C_Tratamiento":"",
           "C_Nickname":"",
           "C_Razon_Soc":"",
           "C_Sigla":"",
           "C_Icn":"",
           "C_Acto_Creac":"",
           "I_Ciudad_Cre":"",
           "I_Sector":"6",
           "C_Tipo_Sociedad":"",
           "C_Objeto":"",
           "C_Acto_Ad":"",
           "F_Fec_Acto":"",
           "C_Sector_Pub":"",
           "I_Nro_Empleados":"0",
           "F_Fec_Registro":"",
           "I_Tipo_Sueldo":"0",
           "F_Fec_Fundacion":"",
           "C_Vigencia":"",
           "C_Fondo_Emp":"",
           "I_Cod_Emp":"4",
           "B_resultado":true,
           "C_mjs":"Consulta Exitosa."
        },
        "lineasCreditoHab_response":{
           "BRta":true,
           "SMsj":"Los datos de las lineas de crédito fueron cargadas correctamente.",
           "ListLineaCredito":{
              "LineaCreditoDTO":[
                 {
                    "ICodigo":504,
                    "SDescripcion":"ESPECIAL"
                 },
                 {
                    "ICodigo":900,
                    "SDescripcion":"COOPRIMAS"
                 }
              ]
           }
        },
        "validarLineaSolicitud_request":{
           "sCodLinea":"504",
           "lCodPersona":3040
        },
        "validarLineaSolicitud_response":{
           "bRta":true,
           "iCodigo":0
        },
        "obtenerValoresMaximos_request":{
           "sCodCredito":"504",
           "sIdentificacion":"1013643973",
           "sPeriodicidad":"Semestral"
        },
        "obtenerValoresMaximos_response":{
           "bRta":true,
           "sCupoMaximo":"10,000,000.00",
           "sMsj":"El plazo maximo asignado es de 220 meses. El cupo máximo asignado para su solictud es de $10000000",
           "sPlazoMaximo":"220"
        },
        "simuladorCreditoAtr_request":{
           "sCodCredito":"504",
           "sIdentificacion":"1013643973",
           "sMonto":"351558",
           "sNroCuota":"1",
           "xsFormaPeriodicidad":"5"
        },
        "simuladorCreditoAtr_response":{
           "bRta":true,
           "bdValorCuota":351558,
           "rLiquidacion":{
              "LiquidacionAtr":[
                 {
                    "n_num_cuo":"1",
                    "f_fecha":"05/02/2022",
                    "n_sal_ini":"351558.00",
                    "n_capital":"351558.00",
                    "n_int_1":"0.00",
                    "n_int_2":"0.00",
                    "n_int_3":"",
                    "n_int_4":"",
                    "n_int_5":"",
                    "n_int_6":"",
                    "n_int_7":"",
                    "n_int_8":"",
                    "n_int_9":"",
                    "n_int_10":"",
                    "n_cap_tf":"0.00",
                    "n_int_tf":"0.00",
                    "n_total":"351558.00",
                    "n_sal_fin":"0.00",
                    "n_intnom_1":"Interes Corr",
                    "n_intnom_2":"Interes mora ",
                    "n_intnom_3":"",
                    "n_intnom_4":"",
                    "n_intnom_5":"",
                    "n_intnom_6":"",
                    "n_intnom_7":"",
                    "n_intnom_8":"",
                    "n_intnom_9":"",
                    "n_intnom_10":""
                 }
              ]
           },
           "sMsj":"Se ha realizado correctamente la simulación del valor de la cuota.",
           "sTasa":"0.0125"
        },
        "saveSolCred_request":{
           "IPersona":{
              "ICodigo":3040,
              "CNombres":"ANDRÉS CAMILO",
              "CApellidos":"SIERRA HORMIGA",
              "CIdentificacion":"1013643973",
              "ITipoIdent":1,
              "CEstado":"A"
           },
           "IEstadoSolicitud":{
              "ICodigo":1,
              "CDescripcion":"ACT"
           },
           "CRequiereCodeudor":"N",
           "ITipoCredito":{
              "ICodigo":504,
              "CNombre":"ESPECIAL",
              "CEstado":"ACT"
           },
           "NValorCuota":351558,
           "ICodPeriodo":5,
           "NMontoSolicitado":"351558",
           "IPlazo":"1"
        },
        "saveSolCred_response":{
           "bRta":true,
           "sMsj":"Se ha guardado la información exitosamente con el siguiente número de solicitud: 20210805358",
           "solicitudCredito":{
              "CRequiereCodeudor":"N",
              "FFechaSolicitud":"2021-08-05T12:40:52.253-05:00",
              "ICodPeriodo":5,
              "ICodigo":358,
              "IEstadoSolicitud":{
                 "ICodigo":1
              },
              "INumRadic":"20210805358",
              "IPersona":{
                 "CApellidos":"SIERRA HORMIGA",
                 "CEstado":"A",
                 "CIdentificacion":"1013643973",
                 "CNombres":"ANDRÃ‰S CAMILO",
                 "ICodigo":3040,
                 "ITipoIdent":1
              },
              "IPlazo":1,
              "ITipoCredito":{
                 "CEstado":"ACT",
                 "CNombre":"ESPECIAL",
                 "ICodigo":504
              },
              "NMontoSolicitado":351558,
              "NValorCuota":351558,
              "comunicacionList":[

              ],
              "historiaSolCreditoList":[

              ],
              "referenciaSolCreditoList":[

              ]
           }
        },
        "getAllParams_response":[
           {
              "CDescripcion":"Cantidad de caracteres de la llave de aceptacion de la solicitud de crédito",
              "CEstado":"ACT",
              "CNombre":"CANT_KEY_SOL_CRED",
              "CValor":"6",
              "ICodigo":2
           },
           {
              "CDescripcion":"Minutos de vigencia del token de aceptacion de la solicitud de crédito",
              "CEstado":"ACT",
              "CNombre":"MINUTOS_VIG_TOKEN_SOL_CRED",
              "CValor":"15",
              "ICodigo":3
           },
           {
              "CDescripcion":"NUMERO DE DIAS DE VIGENCIA DEL CAMBIO DE LA PRIMERA CONTRASENA",
              "CEstado":"ACT",
              "CNombre":"DIAS_VIGENCIA_PRI_CLAVE",
              "CValor":"365",
              "ICodigo":4
           },
           {
              "CDescripcion":"CLAVE DEL SERVIDOR DE CORREO ELECTRONICO",
              "CEstado":"ACT",
              "CNombre":"PASS_MAIL",
              "CValor":"ethossoftware",
              "ICodigo":5
           },
           {
              "CDescripcion":"CORREO DE SALIDA DEL SERVIDOR DE CORREO ELECTRONICO",
              "CEstado":"ACT",
              "CNombre":"CORREO_MAIL",
              "CValor":"ethosw@gmail.com",
              "ICodigo":6
           },
           {
              "CDescripcion":"PUERTO DEL SERVIDOR DE CORREO ELECTRONICO",
              "CEstado":"ACT",
              "CNombre":"PUERTO_MAIL",
              "CValor":"587",
              "ICodigo":7
           },
           {
              "CDescripcion":"CLAVE DEL SERVIDOR DE CORREO ELECTRONICO",
              "CEstado":"ACT",
              "CNombre":"PASS_MAIL",
              "CValor":"ethossoftware",
              "ICodigo":9
           },
           {
              "CDescripcion":"CORREO DE SALIDA DEL SERVIDOR DE CORREO ELECTRONICO",
              "CEstado":"ACT",
              "CNombre":"CORREO_MAIL",
              "CValor":"ethosw@gmail.com",
              "ICodigo":10
           },
           {
              "CDescripcion":"PUERTO DEL SERVIDOR DE CORREO ELECTRONICO",
              "CEstado":"ACT",
              "CNombre":"PUERTO_MAIL",
              "CValor":"587",
              "ICodigo":11
           },
           {
              "CDescripcion":"NOMBRE DEL SERVIDOR DE CORREO ELECTRONICO",
              "CEstado":"ACT",
              "CNombre":"HOST_MAIL",
              "CValor":"smtp.gmail.com",
              "ICodigo":13
           },
           {
              "CDescripcion":"URL WSDL de administracion",
              "CEstado":"ACT",
              "CNombre":"WS_ADMIN",
              "CValor":"http://10.10.30.35/WSAdministracionCoopedac/WSClientAdministracion.asmx?WSDL",
              "ICodigo":14
           },
           {
              "CDescripcion":"URL WSDL del Web Services de Cygnus",
              "CEstado":"ACT",
              "CNombre":"WS_CYGNUS",
              "CValor":"http://10.10.30.35/WSCygnusCoopedac/WSClientCygnus.asmx?WSDL",
              "ICodigo":15
           },
           {
              "CDescripcion":"Tipos de documento habiles para visualizar en las plataformas",
              "CEstado":"ACT",
              "CNombre":"TIPOS_IDEN_HABILES",
              "CValor":"1,3,5",
              "ICodigo":16
           },
           {
              "CDescripcion":"Lineas de crédito habilitadas",
              "CEstado":"ACT",
              "CNombre":"LINEA_CREDITO_HABILITADA",
              "CValor":"504,900",
              "ICodigo":18
           },
           {
              "CDescripcion":"CLAVE DEL SERVIDOR DE LA BASE DE DATOS DEL CORE",
              "CEstado":"ACT",
              "CNombre":"DB_SERVER_PASSWORD_CORE",
              "CValor":"ethos",
              "ICodigo":19
           },
           {
              "CDescripcion":"USUARIO DEL SERVIDOR DE LA BASE DE DATOS DEL CORE",
              "CEstado":"ACT",
              "CNombre":"DB_SERVER_USERNAME_CORE",
              "CValor":"system",
              "ICodigo":20
           },
           {
              "CDescripcion":"NOMBRE DE LA BASE DE DATOS DEL CORE",
              "CEstado":"ACT",
              "CNombre":"DB_BD_CORE",
              "CValor":"coopedac",
              "ICodigo":21
           },
           {
              "CDescripcion":"URL WSDL de Web Services de Simulador de crédito",
              "CEstado":"ACT",
              "CNombre":"WS_SIM_CREDITO",
              "CValor":"http://10.10.30.35/WSSimuladorCreditoCoopedac/WSSimuladorCreditoCoopedac.asmx?WSDL",
              "ICodigo":22
           },
           {
              "CDescripcion":"Codigos de las periodicidades habiles para solicitud de crédito",
              "CEstado":"ACT",
              "CNombre":"PERIODICIDAD_HAB_SOL_CRED",
              "CValor":"1",
              "ICodigo":23
           },
           {
              "CDescripcion":"CANTIDAD DE DIGITOS PARA EL SALT PASSWORD",
              "CEstado":"ACT",
              "CNombre":"CANT_SALT",
              "CValor":"10",
              "ICodigo":24
           },
           {
              "CDescripcion":"Correo al que llegaran las notificaciones generadas por solicitud de créditos",
              "CEstado":"ACT",
              "CNombre":"EMAIL_NOT_SOL_CRED",
              "CValor":"brayan.moreno@ebssas.com",
              "ICodigo":25
           },
           {
              "CDescripcion":"URL API SMS PRIORITARIO",
              "CEstado":"ACT",
              "CNombre":"URL_API_SMS_P",
              "CValor":"https://api103.hablame.co/api/sms/v3/send/priority",
              "ICodigo":26
           },
           {
              "CDescripcion":"URL API SMS MERCADO",
              "CEstado":"ACT",
              "CNombre":"URL_API_SMS_M",
              "CValor":"https://api103.hablame.co/api/sms/v3/send/marketing",
              "ICodigo":27
           },
           {
              "CDescripcion":"CREDENCIALES API SMS",
              "CEstado":"ACT",
              "CNombre":"CRED_AUTH_API_SMS",
              "CValor":"10013292;jn4RnoSLb4TyCMCRSn4cyscsUV7iq8;b748c2c4e4366334ee1f31cd508fe33f",
              "ICodigo":28
           },
           {
              "CDescripcion":"Listas restrictivas que requieren gestor",
              "CEstado":"ACT",
              "CNombre":"LISTAS_AML_GESTOR",
              "CValor":"4223,1992",
              "ICodigo":29
           },
           {
              "CDescripcion":"Listas restrictivas de baneo inmediato",
              "CEstado":"ACT",
              "CNombre":"LISTAS_AML_BAN",
              "CValor":"3875,1993",
              "ICodigo":30
           },
           {
              "CDescripcion":"URL WSDL de creacion ctas de ahorro",
              "CEstado":"ACT",
              "CNombre":"WS_CTASAHORRO",
              "CValor":"http://localhost/Ws_CuentaAhorros/Ws_CuentaAhorros.asmx?WSDL",
              "ICodigo":31
           }
        ],
        "consultarAML_response":{
           "datetime":"2021-08-05 12:41:10",
           "elapsed_time":"0.032132863998413",
           "id_bitacora":"51186",
           "results":[
              {
                 "blocked":false,
                 "datos_amlnews":[

                 ],
                 "datos_pro":[

                 ],
                 "datos_tsti":[

                 ],
                 "doc_id":"1013643973",
                 "item_no":"2",
                 "nombre":"ANDRES CAMILO SIERRA HORMIGA"
              }
           ]
        },
        "amlGenerarPDF_response":{
           "flag":"true",
           "nombreArchivo":"1013643973.pdf",
           "ruta":"/home/u824306342/domains/ebscreditodigital.com/public_html/appswebpre/FTP_COOPEDAC/",
           "sMsg":"PDF generado correctamente"
        },
        "obtenerDatosPersonales_request":{
           "codPersona":"3040",
           "idSolCred":"358"
        },
        "obtenerDatosPersonales_response":{
           "bRTa":true,
           "datosPersonales":{
              "CDireccionResidencia":"direccion pruebas desarrollo",
              "CEmail":"andres.sierra@ebssas.com",
              "CNumCelular":"3006476345",
              "ICodigo":30,
              "IPersona":{
                 "CApellidos":"SIERRA HORMIGA",
                 "CEstado":"ACT",
                 "CIdentificacion":"123456",
                 "CNombres":"ANDRES CAMILO",
                 "ICodigo":3040,
                 "ITipoIdent":1
              }
           },
           "ocupacionPersona":{
              "CEmpresa":"PENSIONADOS AEROCIVIL",
              "IPersona":{
                 "CApellidos":"SIERRA HORMIGA",
                 "CEstado":"ACT",
                 "CIdentificacion":"123456",
                 "CNombres":"ANDRES CAMILO",
                 "ICodigo":3040,
                 "ITipoIdent":1
              },
              "ITipoContrato":{
                 "ICodigo":9,
                 "SDescripcion":"PENSIONADO"
              }
           },
           "operacionFinanciera":{
              "ICodigo":55,
              "IPersona":{
                 "CApellidos":"SIERRA HORMIGA",
                 "CEstado":"ACT",
                 "CIdentificacion":"123456",
                 "CNombres":"ANDRES CAMILO",
                 "ICodigo":3040,
                 "ITipoIdent":1
              },
              "NSalario":2000000,
              "NValorActivos":25000000,
              "NValorEgresos":1500000,
              "NValorGastos":2000000,
              "NValorIngresos":3000000,
              "NValorPasivos":5000000
           },
           "sMjs":"Consulta exitosa"
        },
        "obtenerAperturaCuenta_response":{
           "type":"responseAperturaCuentaSolCred"
        }
     }`;
    return this.importProgramState(json);
  }
}
