import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { FinTransaccionComponent } from './aplicativo/cliente/fin-transaccion/fin-transaccion.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'cliente/login',
    pathMatch: 'full'
  },

  {
    path: 'cliente',
    loadChildren: () => import('./aplicativo/cliente/cliente.module').then(m => m.ClienteModule)
  },
  {
    path: 'administrador',
    loadChildren: () => import('./aplicativo/administrador/administrador.module').then(m => m.AdministradorModule)
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/asociado/asociado.module').then(m => m.AsociadoModule)
  },
  {
    path: 'fin-transaccion',
    loadChildren: () => import('./aplicativo/cliente/cliente.module').then(m => m.ClienteModule)
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'cliente/login'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {

  }
}
