// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  // URL_SERVICE: 'https://webbankingbe.coopedac.com:8080/WSRestApiCoopedacBack/APIRest/',

  // URL_SERVICE: 'http://150.136.51.89:8080/WSRestApiCoopedacBack/APIRest/',
//  URL_SERVICE: 'http://localhost:8080/WSRestApiCoopedacBack/APIRest/', //Local
  URL_SERVICE: 'https://tscoopedacportalbe.ebsproductodigital.com/WSRestApiCoopedacBack/APIRest/', //Pruebas
 // URL_SERVICE: 'https://webbankingbe.coopedac.com:8080/WSRestApiCoopedacBack/APIRest/', //PRODUCCION
  // URL_SERVICE: 'http://localhost:8080/WSRestApiCoopdisalud/APIRest/',
  //URL_SERVICE: 'http://10.50.1.12:8080//WSRestApiCoopedac/APIRest/', //Desarrollo
  // URL_SERVICE: 'http://129.213.50.143:8081/WSRestApiCoopedacPortal/APIRest/', //Desarrollo
  // URL_SERVICE: 'http://129.213.50.143:8081/WSRestApiCoopedacBack/APIRest/', //Desarrollo
  // URL_SERVICE: 'http://150.136.57.80:8081/WSRestApiCoopedacBackPortal/APIRest/', //Pruebas [IP publica]
  // URL_SERVICE: 'http://10.10.30.35:8888/WSRestApiCoopedac/APIRest/',     //pruebas Oficina
  // URL_SERVICE: 'http://190.85.106.122:8888/WSRestApiCoopedac-v2/APIRest/', //Pruebas [IP Pública del servidor 35]


  // Productos del portal web
  CREDITO: '1',
  APORTES: '2',
  AHORRO_A_LA_VISTA: '3',
  RESERVAS: '4',
  AUXILIOS: '5',
  CDAT: '6', //5
  AHORRO_CONTRACTUAL: '7',
  CONSULTA_DE_SERVICIOS: '8',
  CONSULTA_DE_DEVOLUCIONES: '9',

  //url obtener ip
  URL_CONSULTAR_IP: 'https://api.ipify.org/?format=json',
  USER_BACKEND:'UTI5d1pXUmhZekV5TXlvPQ==',
  PASSWORD_BACKEND:'UTI5dmNHVmtZV05mY0c5eWREUnNLZz09'
};

// Productos del portal web PSE
// ng build --output-hashing=all --base-href /CoopedacTesting/
// COMPILAR PORTAL WEB
// ng build --base-href /PortalCoopedacTest/ --output-hashing=all

 //ng build --output-hashing=all --base-href /CoopedacProd/   //pruebas/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
