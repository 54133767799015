import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TimeOutService implements OnDestroy {
  private inactivityTimeout: number;
  private inactivityInterval: any;

  constructor(private router: Router) {
    this.startTimer();
  }

  private startTimer() {
    this.resetTimer();

    // DOM Events
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keypress', this.resetTimer);
    document.addEventListener('mousedown', this.resetTimer);
    document.addEventListener('touchstart', this.resetTimer);
    document.addEventListener('click', this.resetTimer);
    document.addEventListener('scroll', this.resetTimer, true);
  }

  private resetTimer = () => {
    clearTimeout(this.inactivityInterval);
    this.inactivityTimeout = 3; // Inactivity timeout in minutes
    this.inactivityInterval = setTimeout(this.logout, this.inactivityTimeout * 60 * 1000);
  };

  resetCounter() {
    clearTimeout(this.inactivityInterval);
    this.startTimer();
  }

  logout = () => {
    alert('Su sesión ha expirado por inactividad');
    this.router.navigate(['/cliente/login']);
  };

  ngOnDestroy() {
    clearTimeout(this.inactivityInterval);
    document.removeEventListener('mousemove', this.resetTimer);
    document.removeEventListener('keypress', this.resetTimer);
    document.removeEventListener('mousedown', this.resetTimer);
    document.removeEventListener('touchstart', this.resetTimer);
    document.removeEventListener('click', this.resetTimer);
    document.removeEventListener('scroll', this.resetTimer, true);
  }
}
